import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';
import {Router, ActivatedRouteSnapshot, CanActivate} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {GlobalService} from './global.service';
import decode from 'jwt-decode';
import {PermissionService} from './permission.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
    authState = new BehaviorSubject(false);
    constructor(
        public global: GlobalService,
        public jwtHelper: JwtHelperService,
        public router: Router,
        private permissionService: PermissionService
    ) {}

    public canActivate(route: ActivatedRouteSnapshot): boolean {
        const noLogin = route.data.noLogin;
        if (noLogin === true) {
            return true;
        } else {
            const token = this.global.getLocalStorage('tpAdminToken');
            if (typeof token === 'undefined' || this.jwtHelper.isTokenExpired(token)) {
                this.global.pushPage('login');
                return false;
            } else {
                //const authData = route.data;
                const tokenData = decode(token);
                // this will be passed from the route config
                // to get data passed on route use the data property route.data
                // E.g. route.data.hasOwnProperty(dataKey)
                if (!this.permissionService.isAuthorized(tokenData['userRole'], route.routeConfig?.path)) {
                    this.global.pushPage('login');
                    return false;
                } else {
                    if (!this.global.appInitiated) {
                        this.global.initLoggedInUser();
                    }
                    return true;
                }
            }
        }
    }
}
