import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {
    AlertController,
    LoadingController,
    MenuController,
    ModalController,
    NavController,
    PopoverController,
} from '@ionic/angular';
import {catchError, finalize, map} from 'rxjs/operators';
import {HttpHeaders, HttpClient} from '@angular/common/http';
import {Observable, from, of} from 'rxjs';
import $ from 'jquery';
import decode from 'jwt-decode';
import * as _ from 'lodash';
import * as globalPaths from '../pathConfig';
import * as moment from 'moment';
import {Camera, CameraResultType, CameraSource, Photo} from '@capacitor/camera';
import {EventService} from './event.service';
import {Browser} from '@capacitor/browser';

@Injectable({
    providedIn: 'root',
})
export class GlobalService {
    // public API_URL = 'http://localhost:3000/api';
    public $ = $;
    public _ = _;
    public moment = moment;
    public isMobileApplication = globalPaths.IS_MOBILE_APPLICAION;
    public API_URL = globalPaths.API_URL;
    public APP_URL = globalPaths.APP_URL;
    public SITE_RUNNING = globalPaths.SITE_RUNNING;
    public routerOutlet;
    public loader;
    public httpReqOptions = {headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'})};
    loadingAnimationRunning = false;
    showBackIcon = false;
    loggedInUser = null;
    appInitiated = false;
    public globalFileUploadInput;
    public multipleFileUpload;
    public FILEOF;
    public fakeItems = [1, 2, 3, 4, 5, 6];
    public renderer;
    public limitForEntries = 20;
    public currencyCode = 'CAD';
    public currencySymbol = '$';
    isWatching = false;
    constructor(
        public http: HttpClient,
        public translate: TranslateService,
        public modalCtrl: ModalController,
        public navCtrl: NavController,
        public loadingCtrl: LoadingController,
        private menuCtrl: MenuController,
        public alertCtrl: AlertController,
        public eventBus: EventService,
        public popover: PopoverController
    ) {
        // for some reason this is causing circular dependency with HTTP interceptors
        // moving it inside timeout seems to fix the problem
        setTimeout(() => {
            translate.setDefaultLang('en');
            translate.use('en');
        });
    }

    initLoggedInUser(redirect = false) {
        const token = this.getLocalStorage('tpAdminToken');
        if (token !== '' && token !== null) {
            try {
                const tokenPayload = decode(token);
                this.appInitiated = true;
                this.loggedInUser = tokenPayload;
                this.loginEssential(redirect);
                return true;
            } catch (error) {
                return false;
            }
        }
        return false;
    }
    loginEssential(redirect) {
        this.sendAjaxRequest('user/verifyUserToken', {}).subscribe((response) => {
            if (response['message'] === 'success') {
                this.menuCtrl.enable(true);
                this.eventBus.broadcastEvent('AppInitatedSet', {});
                if (redirect) {
                    if (this.loggedInUser['userRole'] === 'superAccountant') {
                        this.pushPage('reports');
                    } else {
                        this.pushPage('dashboard');
                    }
                }
            }
        });
    }

    getRequestUrl(requestURL: string) {
        return this.API_URL + '/' + requestURL;
    }
    startDate;
    trackTabs() {
        if (localStorage.getItem('user-activity') === null) {
            localStorage.setItem('user-activity', this.startDate);
            localStorage.setItem('tabs', '1');
        } else if (this.startDate !== localStorage.getItem('user-activity')) {
            const tabs = +localStorage.getItem('tabs') + 1;
            localStorage.setItem('tabs', tabs.toString());
        }
    }
    async showLoading(message: string = '', duration: number = 0) {
        if (!this.loadingAnimationRunning) {
            this.loadingAnimationRunning = true;
            let options = {};
            if (duration !== 0) {
                options = {
                    message,
                    duration,
                };
            } else {
                options = {
                    message,
                };
            }

            this.loader = this.loadingCtrl.create(options);
            return await this.loader.then((a) => {
                a.present().then(() => {
                    // console.log('presented');
                    if (!this.loadingAnimationRunning) {
                        a.dismiss().then(() => {
                            // console.log('abort presenting')
                        });
                    }
                });
            });
        }
    }

    async hideLoading() {
        try {
            this.loadingAnimationRunning = false;
            return await this.loadingCtrl.dismiss().then(() => {
                // console.log('dismissed')
            });
        } catch (err) {
            if (typeof err === 'string' && !err.includes('overlay does not exist')) {
                console.error(err);
            }
        }
    }

    /*
		This function show simple alert with ok button as per environment
	*/
    async basicAlert(inTitle, inMessage, inTranslate = false) {
        if (inTranslate === true) {
            if (inTitle !== '') {
                inTitle = this.translate.instant(inTitle);
            }
            if (inMessage !== '') {
                inMessage = this.translate.instant(inMessage);
            }
        }

        const alert = await this.alertCtrl.create({
            header: inTitle,
            subHeader: '',
            message: inMessage,
            buttons: [this.translate.instant('label.ok')],
        });
        await alert.present();
    }

    param(data) {
        return $.param(data);
    }

    setToken() {
        const tempToken = this.getLocalStorage('tpAdminToken');
        if (tempToken !== 'false' && tempToken !== '') {
            this.httpReqOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/x-www-form-urlencoded',
                    // eslint-disable-next-line quote-props
                    Authorization: 'Bearer ' + tempToken,
                }),
            };
        }
    }
    sendAjaxRequest(requestURL, requestParam = {}, redirectOnUnauthorized = true, download = false): Observable<any> {
        requestURL = this.getRequestUrl(requestURL);
        this.setToken();

        if (this.isMobileApplication) {
            const httpResult = from(this.http.post(requestURL, this.param(requestParam), this.httpReqOptions));
            return httpResult.pipe(
                map((response) => {
                    const result = response;
                    if (redirectOnUnauthorized) this.handleUnauthorizedResponse(result);
                    return result;
                })
            );
        } else {
            const headers = download ? {...this.httpReqOptions, responseType: 'blob' as 'json'} : this.httpReqOptions;
            return this.http.post(requestURL, this.param(requestParam), headers).pipe(
                map((response) => {
                    const result = response;
                    if (redirectOnUnauthorized) this.handleUnauthorizedResponse(result);
                    return result;
                })
            );
        }
    }

    handleUnauthorizedResponse(result) {}

    sendTPRequest(requestURL, requestParam = {}, redirectOnUnauthorized = true): Observable<any> {
        requestURL = 'https://api.tenantpay.com/' + requestURL;
        requestParam['token'] = '06f6a489209115c5cef3f45036aad3ec';
        const httpReqOptions = {headers: new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded'})};

        return this.http.post(requestURL, this.param(requestParam), httpReqOptions).pipe(
            map((response) => {
                const result = response;
                if (redirectOnUnauthorized) this.handleUnauthorizedResponse(result);
                return result;
            })
        );
    }

    async closeModal(data = {}) {
        const topModal = await this.modalCtrl.getTop();
        if (topModal) {
            await this.modalCtrl.dismiss(data);
        }
    }

    goBackOrPush(link) {
        if (this.routerOutlet.canGoBack()) {
            this.goBack();
        } else {
            this.pushPage(link);
        }
    }
    pushPage(link) {
        this.showBackIcon = true;
        this.navCtrl.navigateForward(link, {animated: false});
    }
    goBack() {
        this.navCtrl.back();
    }

    countFinished = false;
    logoutUser() {
        this.isWatching = false;
        localStorage.clear();
        this.loggedInUser = false;
        this.countFinished = true;
        this.navCtrl.navigateRoot('login');
    }
    openSideMenu() {
        this.menuCtrl.open('sideMenu');
    }
    closeSideMenu() {
        this.menuCtrl.close('sideMenu');
    }

    generateRandomNumber(inFrom, to) {
        return Math.floor(Math.random() * to) + inFrom;
    }
    /******************* Photo Uploading Functions ************************* */
    clickFileUpload() {
        if (this.isMobileApplication) {
            this.mobileUploadMenu();
        } else {
            // This function is invoking HTML element onchange function
            // eslint-disable-next-line max-len
            // <input type="file"  [multiple]="global.multipleFileUpload" #globalFileUpload id="globalFileUpload" name="globalFileUpload" (change)="global.initializeUpload($event)"  accept="images/*"  style="display: none;"  />
            // input type='file' mention in app.component.html page
            const fileUploadClickEvent = new MouseEvent('click', {});
            this.globalFileUploadInput.nativeElement.dispatchEvent(fileUploadClickEvent);
        }
    }

    initializeUpload(e) {
        const file = {...e.target.files[0], type: e.target.files[0].type, name: encodeURI(e.target.files[0].name)};
        this.sendAjaxRequest('upload/presignedUrl', {
            filename: file.name,
            fileType: file.type,
            destination: this.FILEOF.dest,
        }).subscribe(async (response) => {
            if (response.message === 'success') {
                this.showLoading();
                const res = await fetch(response.uploadUrl, {
                    method: 'PUT',
                    body: e.target.files[0],
                    headers: {
                        'Content-Type': file.type,
                    },
                });
                const uploadEvent = {
                    fileDest: this.FILEOF.dest,
                    originalNameWithExt: file.name,
                    fileUrl: response.url,
                    fileNameWithExt: response.fileName,
                    downloadUrl: response.downloadUrl,
                };
                this.hideLoading();
                this.eventBus.broadcastEvent(this.FILEOF.event, [uploadEvent]);
                $('#globalFileUpload').val('');
            }
        });
    }

    // initializeUpload(e) {
    // 	const file = e.target.files[0];
    // 	const fileName = file.name;
    // 	const ftype = file.type;
    // 	let isImage = false;
    // 	let isVideo = false;
    // 	switch (ftype) {
    // 		case 'image/png': case 'image/gif': case 'image/jpeg': case 'image/pjpeg':
    // 			isImage = true;
    // 			break;
    // 		case 'video/mp4':
    // 			isVideo = true;
    // 			isImage = false;
    // 			break;
    // 	}
    // 	const tempGlobal = this;
    // 	this.FILEOF.isImage = isImage;
    // 	this.FILEOF.videoUpload = isVideo;
    // 	if (isImage || isVideo || true) {
    // 		this.showLoading();
    // 		this.uploadFile(e);
    // 	} else {
    // 		$('#globalFileUpload').val('');
    // 		this.basicAlert('', this.translate.instant('label.u_can_only_upload_images'));
    // 	}
    // }

    // uploadFile = function(event) {
    // 	const data = new FormData();
    // 	const files = event.target.files;
    // 	// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    // 	$.each(files, function(key, value) {
    // 		data.append('file', value);
    // 	});
    // 	data.append('dest', this.FILEOF.dest);
    // 	data.append('fileUpload', this.FILEOF.fileUpload);
    // 	data.append('thumbDest', this.FILEOF.thumbDest);
    // 	data.append('createThumb', this.FILEOF.createThumb);
    // 	data.append('newFileName', this.FILEOF.newFileName);
    // 	data.append('isImage', this.FILEOF.isImage);
    // 	data.append('videoUpload', this.FILEOF.videoUpload);
    // 	if(this.FILEOF.useOriginalFileName) {
    // 		data.append('useOriginalFileName', this.FILEOF.useOriginalFileName);
    // 	}

    // 	if (this.FILEOF.uploadToS3 === false)
    // 		data.append('uploadToS3', this.FILEOF.uploadToS3);

    // 	const tempGlobal = this;
    // 	const tempAccessToken = this.getLocalStorage('tpAdminToken');
    // 	$.ajax({
    // 		// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    // 		xhr() {
    // 			const xhr = new XMLHttpRequest();
    // 			return xhr;
    // 		},
    // 		url: this.API_URL + '/media/uploadFile',
    // 		type: 'POST',
    // 		data,
    // 		cache: false,
    // 		dataType: 'json',
    // 		headers: {
    // 			Authorization: 'Bearer ' + tempAccessToken
    // 		},
    // 		processData: false, // Don't process the files
    // 		contentType: false, // Set content type to false as jQuery will tell the server its a query string request
    // 		// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
    // 		success(returnData: any) {
    // 			tempGlobal.hideLoading();
    // 			tempGlobal.eventBus.broadcastEvent(tempGlobal.FILEOF.event, returnData);
    // 			$('#globalFileUpload').val('');
    // 		}
    // 	});

    // };

    async mobileUploadMenu() {
        const image = await Camera.getPhoto({
            quality: 30,
            allowEditing: false,
            resultType: CameraResultType.Uri,
            source: CameraSource.Prompt, // Camera, Photos or Prompt!
        });

        if (image) {
            const response = await fetch(image.webPath);
            const blob = await response.blob();
            this.startUpload(blob);
        }
    }
    async startUpload(imageBlob) {
        const formData = new FormData();
        formData.append('file', imageBlob);
        formData.append('dest', this.FILEOF.dest);
        formData.append('fileUpload', this.FILEOF.fileUpload);
        formData.append('thumbDest', this.FILEOF.thumbDest);
        formData.append('createThumb', this.FILEOF.createThumb);
        formData.append('newFileName', this.FILEOF.newFileName);
        formData.append('isImage', this.FILEOF.isImage);
        formData.append('videoUpload', this.FILEOF.videoUpload);
        if (this.FILEOF.uploadToS3 === false) formData.append('uploadToS3', this.FILEOF.uploadToS3);

        this.uploadData(formData);
    }
    async uploadData(formData: FormData) {
        this.showLoading();
        const url = this.API_URL + '/uploadFile';
        this.http
            .post(url, formData, this.httpReqOptions)
            .pipe(
                finalize(() => {
                    this.hideLoading();
                })
            )
            .subscribe((uploadResponse) => {
                const response = uploadResponse;
                this.eventBus.broadcastEvent(this.FILEOF.event, response);
                this.hideLoading();
            });
    }
    onFail(message) {
        alert('Failed because: ' + message);
    }

    /*************************Validation Functions*************************** */
    isFormValid(formToCheck, showAlert = true) {
        let isValid = true;
        let firstInvalidElementId = '';
        formToCheck['validatedOnce'] = true;
        let allControlsAreValid = true;

        // eslint-disable-next-line guard-for-in
        for (const controlName in formToCheck.controls) {
            const control = formToCheck.controls[controlName];
            if (!control.valid && control.status !== 'DISABLED') {
                if (isValid) {
                    if (document.querySelector('#' + controlName) != null) {
                        firstInvalidElementId = controlName;
                    }
                }
                control.updateValueAndValidity();
                control.markAsTouched();
                isValid = false;
            }

            if (!isValid) {
                // in case if errors do not include required error and value is empty then it should be considered as valid
                if (
                    control.errors != null &&
                    !control.errors.hasOwnProperty('required') &&
                    !control.errors.hasOwnProperty('myRequired') &&
                    (control.value === '' || control.value == null)
                ) {
                    isValid = true;
                }
            }

            // even if one invalid element found then is should return invalid message
            if (isValid === false) {
                allControlsAreValid = false;
            }
        }

        if (!allControlsAreValid && showAlert) {
            this.basicAlert('', 'errors.please_fill_all_required_fields', true);
        }

        return allControlsAreValid;
    }
    isFieldInvalid(field: any) {
        if (typeof field === 'undefined' || field == null || field === '') {
            return false;
        } else {
            // this.consoleLog(field);
            return field.errors != null && (field.dirty || field.touched);
        }
    }
    getFieldValidationErrorMessage(field: any) {
        if (field.errors != null) {
            if (field.name === 'email') {
                if (field.errors.required) {
                    return this.translate.instant('errors.required');
                } else {
                    return this.translate.instant('errors.email_invalid');
                }
            }
            if (field.name === 'phoneNumber') {
                if (field.errors.required) {
                    return this.translate.instant('errors.required');
                } else {
                    return this.translate.instant('errors.phone_invalid');
                }
            }
            if (field.errors.required) {
                return this.translate.instant('errors.required');
            } else if (field.errors.email) {
                return this.translate.instant('errors.email_invalid');
            } else if (field.errors.phone) {
                return this.translate.instant('errors.phone_invalid');
            } else if (field.errors.number) {
                return this.translate.instant('errors.enter_valid_number');
            } else {
                return this.translate.instant('errors.required');
            }
        }
    }

    processAdress(unProcessedAddress) {
        const address = {};
        unProcessedAddress.forEach((element) => {
            switch (element['types'][0]) {
                case 'street_number':
                    address['buildingNumber'] = element['long_name'];
                    break;

                case 'route':
                    address['streetName'] = element['long_name'];
                    break;

                case 'administrative_area_level_1':
                    address['province'] = element['long_name'];
                    address['shortProvinceCode'] = element['short_name'];
                    break;

                case 'sublocality_level_1':
                case 'locality':
                    address['city'] = element['long_name'];
                    break;

                case 'country':
                    address['country'] = element['long_name'];
                    address['shortCountryCode'] = element['short_name'];
                    break;

                case 'postal_code':
                    address['postalCode'] = element['long_name'];
                    break;
            }
        });

        return address;
    }

    removeFromStorage(key) {
        try {
            return localStorage.removeItem(key);
        } catch (error) {
            return '';
        }
    }
    getLocalStorage(key) {
        try {
            return localStorage[key];
        } catch (error) {
            return '';
        }
    }
    setLocalStorage(key, value) {
        try {
            localStorage[key] = value;
        } catch (error) {
            return false;
        }
    }
    isEmptyObject(obj) {
        if (obj === 0 || obj == null || typeof obj === 'undefined') {
            return true;
        }
        return Object.keys(obj).length === 0;
    }
    isValidEmail(value) {
        var EMAIL_REGEXP =
            /^[a-z0-9!#$%&'*+\/=?^_`{|}~.-]+@[a-z0-9]([a-z0-9-]*[a-z0-9])?(\.[a-z0-9]([a-z0-9-]*[a-z0-9])?)*$/i;

        if (!EMAIL_REGEXP.test(value)) return false;
        else return true;
    }
    async launchExternal(link, outside = true) {
        let newWin = null;
        if (this.isMobileApplication) {
            if (outside) window.open(link, '_system');
            else {
                const openCapacitorSite = async () => {
                    await Browser.open({url: link});
                };
            }
        } else {
            newWin = window.open(link, '_blank');
        }
    }
    isValidPhoneNumber(value) {
        var PHONE_REHEXP = /^\+?1?\s*\(?-*\.*(\d{3})\)?\.*-*\s*(\d{3})\.*-*\s*(\d{4})$/i;

        if (!PHONE_REHEXP.test(value)) return false;
        else return true;
    }
    downloadFile(url: string): Observable<Blob> {
        return this.http.get(url, {responseType: 'blob'});
    }
    currencyFormatter(currency, sign) {
        const sansDec = currency.toFixed(2);
        const formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return sign + `${formatted}`;
    }
    public isPasswordValid = {
        minLength: false,
        lowerCase: false,
        upperCase: false,
        // eslint-disable-next-line id-blacklist
        number: false,
        symbol: false,
    };
    public allowedSymbols = /[!@#$%^&*]/;
}
