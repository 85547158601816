import {Component, Input, OnInit, OnDestroy} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {UserIdleService} from 'angular-user-idle';
import {timer, Subscription} from 'rxjs';
import {GlobalService} from 'src/app/services/global.service';
import {UserIdleTimerService} from 'src/app/services/userIdle.service';

@Component({
    selector: 'app-countdown-modal',
    templateUrl: './countdown-modal.component.html',
    styleUrls: ['./countdown-modal.component.scss'],
})
export class CountdownModalComponent implements OnInit, OnDestroy {
    @Input() initialCount: number;
    formattedTime: string;
    private countdownSubscription: Subscription;

    constructor(
        private modalController: ModalController,
        private userIdle: UserIdleTimerService,
        private userIdleService: UserIdleService,
        private global: GlobalService
    ) {}
    public countdownReachedZero = this.global.countFinished;
    ngOnInit() {
        //   this.startCountdown();
        this.userIdle.getTimerCountObservable().subscribe((timerCount) => {
            this.updateCountdown(timerCount);
        });
    }
    private updateCountdown(seconds: number) {
        this.formattedTime = this.formatTime(this.userIdleService.getConfigValue().timeout - seconds);
        if (seconds === null) {
            this.countdownReachedZero = true;
            // this.dismiss();
        }
    }

    ngOnDestroy() {
        if (this.countdownSubscription) {
            this.countdownSubscription.unsubscribe();
        }
    }

    private formatTime(seconds: number): string {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        const paddedMinutes = minutes.toString().padStart(2, '0');
        const paddedSeconds = remainingSeconds.toString().padStart(2, '0');
        return `${paddedMinutes}:${paddedSeconds}`;
    }

    ionWillDismiss() {
        if (this.countdownSubscription) {
            this.countdownSubscription.unsubscribe();
        }
    }

    ionDidDismiss() {
        if (this.countdownSubscription) {
            this.countdownSubscription.unsubscribe();
        }
    }

    dismiss() {
        this.modalController.dismiss();
    }
}
