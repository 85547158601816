import { NgModule } from '@angular/core';
import { MomentPipe } from './moment.pipe';
import { ObjectKeysPipe } from './objectKeys.pipe';
import { SafeHtmlPipe, SafeUrlPipe } from './safe.pipe';
import { ObjectStringFilterPipe } from './objectStringFilter.pipe';
import { StringFilterPipe } from './stringFilter.pipe';

@NgModule({
	declarations: [
		MomentPipe,
		ObjectKeysPipe,
		SafeHtmlPipe,
		SafeUrlPipe,
		ObjectStringFilterPipe,
		StringFilterPipe
	],
	imports: [

	],
	exports: [
		MomentPipe,
		ObjectKeysPipe,
		SafeHtmlPipe,
		SafeUrlPipe,
		ObjectStringFilterPipe,
		StringFilterPipe
	]
})
export class PipesModule {
}
