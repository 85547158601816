import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class PermissionService {
    private rolePermissions = {
        superAccountant: ['reports', 'reports/deposits'],
    };

    isAuthorized(userRole: string, routePath: string): boolean {
        if (userRole === 'superAdmin' || userRole === 'superManager') {
            return true;
        }
        const allowedRoutes = this.rolePermissions[userRole] || [];
        return allowedRoutes.includes(routePath || '');
    }
}
