import {Injectable} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {GlobalService} from './global.service';
import {UserIdleService} from 'angular-user-idle';

import {CountdownModalComponent} from '../components/countdown-modal/countdown-modal.component';
import {Subject} from 'rxjs';

/**
 * service for checking screen idleness
 */
@Injectable({providedIn: 'root'})
export class UserIdleTimerService {
    constructor(
        private userIdle: UserIdleService,
        private global: GlobalService,
        private modalController: ModalController
    ) {}

    /*
     *A boolean property indicating whether the idle timer is currently active.
     */
    isTimer?: boolean;
    timerCount = 0;
    private timerCountSubject = new Subject<number>();
    getTimerCountObservable() {
        return this.timerCountSubject.asObservable(); // Return the observable
    }
    /**
     *  Starts monitoring user activity and displays a countdown modal when the idle timer is initiated.
     * Logs the user out upon timeout.
     */
    startWatching() {
        this.userIdle.startWatching();
        this.global.isWatching = true;

        this.userIdle.onTimerStart().subscribe((count) => {
            if (!this.global.loggedInUser) {
                this.stop();
                this.stopWatching();
            }
            this.timerCount = count;
            this.timerCountSubject.next(this.timerCount);
            if (+localStorage.getItem('tabs') === 1) {
                if (!this.isTimer) {
                    this.isTimer = true;
                    this.global.countFinished = false;
                    this.openCountdownModal();
                }
            } else {
                this.resetTimer();
            }
        });

        this.userIdle.onTimeout().subscribe(() => {
            this.global.logoutUser();
        });
    }

    /*
     *Stops the idle timer and performs cleanup.
     */

    stop() {
        this.global.isWatching = false;
        this.userIdle.stopTimer();
        this.cleanupTimer();
    }

    /*
     *Stops monitoring user activity and performs cleanup.
     */
    stopWatching() {
        this.userIdle.stopWatching();
        this.global.isWatching = false;
        this.cleanupTimer();
    }

    /*
     *Resets the idle timer and performs cleanup.
     */
    resetTimer() {
        this.userIdle.resetTimer();
        this.global.isWatching = false;
        this.cleanupTimer();
    }

    /*
     *Opens a countdown modal using Ionic's ModalController, displaying the remaining time until timeout.
     */
    private async openCountdownModal() {
        const countdownModal = await this.modalController.create({
            component: CountdownModalComponent,
            componentProps: {
                initialCount: this.userIdle.getConfigValue().timeout - this.timerCount,
                timerCountObservable: this.getTimerCountObservable(),
            },
            backdropDismiss: false,
            cssClass: 'auto-height-width',
        });

        countdownModal.present();

        countdownModal.onDidDismiss().then(() => {
            this.resetTimer();
        });
    }

    /*
    Resets the `isTimer` property to `false`.
    */

    cleanupTimer() {
        this.isTimer = false;
    }
}
