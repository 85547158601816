import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuardService as AuthGuard} from './services/auth-guard.service';

const routes: Routes = [
    {path: '', redirectTo: 'login', pathMatch: 'full'},
    {
        path: 'folder/:id',
        loadChildren: () => import('./folder/folder.module').then((m) => m.FolderPageModule),
    },
    {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginPageModule),
    },
    /* {
        path: 'buildings',
        loadChildren: () => import('./pages/buildings/buildings.module').then((m) => m.BuildingsPageModule),
        canActivate: [AuthGuard],
        data: {},
    }, */
    {
        path: 'reports',
        loadChildren: () => import('./pages/reports/reports.module').then((m) => m.ReportsPageModule),
        canActivate: [AuthGuard],
    },
    /* {
        path: 'monthly_reports',
        loadChildren: () => import('./pages/reports/monthly-reports/monthly-reports.module').then((m) => m.MonthlyReportsModule),
        canActivate: [AuthGuard],
        data: {userRoleId: ['superAdmin']},
    }, */
    {
        path: 'bank-accounts',
        loadChildren: () => import('./pages/bank-accounts/bank-accounts.module').then((m) => m.BankAccountsPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'transactions',
        loadChildren: () => import('./pages/transactions/transactions.module').then((m) => m.TransactionsPageModule),
        canActivate: [AuthGuard],
    },
    /* {
        path: 'contacts/:tab',
        loadChildren: () => import('./pages/contacts/contacts.module').then((m) => m.ContactsPageModule),
        canActivate: [AuthGuard],
        data: {},
    }, */
    {
        path: 'admins',
        loadChildren: () => import('./pages/admins/admins.module').then((m) => m.AdminsPageModule),
        canActivate: [AuthGuard],
    },

    {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then((m) => m.DashboardPageModule),
        canActivate: [AuthGuard],
    },
    /*  {
        path: 'integrations/yardi',
        loadChildren: () => import('./pages/integrations/yardi/yardi.module').then((m) => m.YardiPageModule),
        canActivate: [AuthGuard],
        data: {},
    }, */
    {
        path: 'settings',
        loadChildren: () => import('./pages/settings/settings.module').then((m) => m.SettingsPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'pay/:referenceId',
        loadChildren: () => import('./pages/pay/pay.module').then((m) => m.PayPageModule),
    },
    {
        path: 'kpi',
        loadChildren: () => import('./pages/kpi/kpi.module').then((m) => m.KPIPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'kpi/customers',
        loadChildren: () => import('./pages/kpi/customers-kpi/customers.kpi.module').then((m) => m.CustomersKPIPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'kpi/transactions',
        loadChildren: () => import('./pages/kpi/transactions-kpi/transactions.kpi.module').then((m) => m.TransactionsKPIPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'kpi/revenue',
        loadChildren: () => import('./pages/kpi/revenue-kpi/revenue.kpi.module').then((m) => m.RevenueKPIPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'billing',
        loadChildren: () => import('./pages/billing/billing.module').then((m) => m.BillingPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'companies',
        loadChildren: () => import('./pages/companies/companies.module').then((m) => m.CompaniesPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'kyc/list',
        loadChildren: () => import('./pages/kyc/kyc-list/kyc-list.module').then((m) => m.KYCListPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'kyc-details/:kycId',
        loadChildren: () => import('./pages/kyc/kyc-details/kyc-details.module').then((m) => m.KYCDetailsPageModule),
        canActivate: [AuthGuard],
    },

    {
        path: 'processing/batches',
        loadChildren: () => import('./pages/processing/processed-transactions/processed-transactions.module').then((m) => m.ProcessedTransactionsPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'processing/exceptions',
        loadChildren: () => import('./pages/processing/exceptions/exceptions.module').then((m) => m.ExceptionsPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'company-details/:companyId',
        loadChildren: () => import('./pages/companies/company-details/company-details.module').then((m) => m.CompanyDetailsPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'str-filings',
        loadChildren: () => import('./pages/str-filings/str-filings.module').then((m) => m.StrFilingModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'company-details/kyc/:companyId',
        loadChildren: () => import('./pages/companies/company-kyc-details/company-kyc-details.module').then((m) => m.CompanyKYCDetailsPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'alerts',
        loadChildren: () => import('./pages/alerts/alerts.module').then((m) => m.AlertsModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'alert-details/:alertID',
        loadChildren: () => import('./pages/alerts/alert-details/alert-details.module').then((m) => m.AlertDetailsModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'tenants/list',
        loadChildren: () => import('./pages/tenants/tenant-list/tenant-list.module').then((m) => m.TenantListModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'tenant-details/:tenantId/:userId',
        loadChildren: () => import('./pages/tenants/tenant-details/tenant-details.module').then((m) => m.TenantDetailsModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'reports/deposits',
        loadChildren: () => import('./pages/reports/monthly-deposits/monthly-deposits.module').then((m) => m.MonthlyDepositsPageModule),
        canActivate: [AuthGuard],
    },
    {path: 'not-found', loadChildren: () => import('./pages/not-found/not-found.module').then((m) => m.NotFoundModule)},
    {path: '**', redirectTo: '/not-found'},
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
