<ion-app>
    <ion-split-pane when="sm" contentId="main-content">
        <ion-menu class="sm:w-64" *ngIf="showSidebar" menuId="sideMenu" contentId="main-content">
            <ion-header class="w-full shadow-none bg-white after:bg-none">
                <ion-toolbar class="mx-auto max-w-7xl">
                    <img src="assets/tenantpay.png" class="h-10 mx-auto" />
                </ion-toolbar>
            </ion-header>
            <ion-content>
                <ion-list id="inbox-list" class="ml-5 mr-2 flex flex-col gap-1">
                    <ion-menu-toggle auto-hide="false" *ngFor="let page of appPages; let i = index">
                        <ion-item
                            class="rounded-lg cursor-pointer hover:text-[--ion-color-primary]"
                            (click)="actionOnBtn(page)"
                            lines="none"
                            detail="false"
                            [class.selected]="activeRoute(page)">
                            <fa-icon class="w-6 h-6 mb-3" slot="start" [icon]="page.icon"></fa-icon>
                            <ion-label class="ml-2">{{ page['title'] }}</ion-label>
                        </ion-item>
                    </ion-menu-toggle>
                </ion-list>

                <ion-list id="labels-list" class="ml-5 mr-2 flex flex-col gap-1 mt-12">
                    <ion-menu-toggle auto-hide="false" *ngFor="let page of otherPages; let i = index">
                        <ion-item
                            class="rounded-lg cursor-pointer hover:text-[--ion-color-primary]"
                            (click)="actionOnBtn(page)"
                            lines="none"
                            detail="false"
                            [class.selected]="activeRoute(page)">
                            <fa-icon class="w-6 h-6 mb-3" slot="start" [icon]="page.icon"></fa-icon>
                            <ion-label class="ml-2">{{ page['title'] }}</ion-label>
                        </ion-item>
                    </ion-menu-toggle>
                </ion-list>
            </ion-content>
        </ion-menu>

        <ion-router-outlet id="main-content"></ion-router-outlet>
    </ion-split-pane>
    <input
        type="file"
        [multiple]="global.multipleFileUpload"
        #globalFileUpload
        id="globalFileUpload"
        name="globalFileUpload"
        (change)="global.initializeUpload($event)"
        accept="images/*"
        style="display: none" />
</ion-app>
